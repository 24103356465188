import { useState } from "react";
import { useTypedSelector } from "../../../../../Store/Redux/store";
import { Trans } from "@lingui/macro";
import styles from "../../styles.module.scss";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import ButtonBase from "@material-ui/core/ButtonBase";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import { IAdminRoom } from "../../../../Store/roomsManagement/models";
import Box from "../../../../../Components/Box";
import Text from "../../../../../Components/Text";
import TableSubmenuButton from "../../../../Components/Buttons/TableSubmenuButton";
import OverflowToolTip from "../../../../../Components/OverflowTooltip";
import RoomStatus from "./RoomStatus";
// import RoomAmenities from "./RoomAmenities";
import RoomDeleteModal from "./RoomDeleteModal";
import RoomManagementForm from "../../RoomManagementForm";
import RoomAmenities from "./RoomsAmenities";

interface Props {
  index: number;
  room: IAdminRoom;
}

export default function RoomsTableBody(props: Props) {
  const { config } = useTypedSelector(state => state);
  const { room, index } = props;

  const [isOpenRoomForm, setIsOpenRoomForm] = useState(false);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  const capacity = room.capacity ? room.capacity : '-';

  // edit from modal
  const onOpenRoomForm = () => {
    setIsOpenRoomForm(o => !o);
  };

  const onCloseRoomForm = () => {
    setIsOpenRoomForm(false);
  };

  // delete modal
  const onOpenDeleteModal = () => {
    setIsOpenDeleteModal(o => !o);
  };

  const onCloseDeleteModal = () => {
    setIsOpenDeleteModal(false);
  };

  return (
    <>
      <TableRow
        classes={{ root: styles.tableRow }}
        hover
        key={index}
        tabIndex={-1}
      >
        <TableCell classes={{ root: styles.tableCell }}>
          <OverflowToolTip text={room.id} />
        </TableCell>
        <TableCell classes={{ root: styles.tableCell }}>
          <OverflowToolTip text={room.name} />
        </TableCell>
        <TableCell classes={{ root: styles.tableCell }}>
          <RoomStatus room={room} />
        </TableCell>
        <TableCell classes={{ root: styles.tableCell }}>
          <RoomAmenities room={room} />
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }}>
          <OverflowToolTip text={capacity} />
        </TableCell>

        <TableCell align="right" classes={{ root: styles.tableCell }}>
          <TableSubmenuButton
            iconButtonName={"room-item-options"}
            iconButtonStyle={{padding: 0, width: '30px', height: '30px'}}
          >
            {
              !isOpenRoomForm && !isOpenDeleteModal ?
                <Box borderStyle="shadow" className="popover-content" dataTestId="room-item-options-popup">
                  <ButtonBase className="popover-item-button" onClick={onOpenRoomForm}>
                    <CreateOutlinedIcon style={{color: config.theme.primary, fontSize: 20}}/>

                    <Box marginStart={5}>
                      <Text color="blue" size="md">
                        <Trans>Edit Room</Trans>
                      </Text>
                    </Box>
                  </ButtonBase>

                  <ButtonBase className="popover-item-button" onClick={onOpenDeleteModal}>
                    <DeleteOutlineIcon style={{color: config.theme.primary, fontSize: 20}}/>

                    <Box marginStart={5}>
                      <Text color="blue" size="md">
                        <Trans>Delete Room</Trans>
                      </Text>
                    </Box>
                  </ButtonBase>
                </Box> : null
            }
          </TableSubmenuButton>
        </TableCell>
      </TableRow>

      <RoomManagementForm
        actionState={'edit'}
        onCancelAction={onCloseRoomForm}
        open={isOpenRoomForm}
        room={room}
      />
      <RoomDeleteModal
        onCancelAction={onCloseDeleteModal}
        open={isOpenDeleteModal}
        room={room}
      />
    </>
  );
}