import { useDispatch } from "react-redux";
import styles from "./styles.module.scss";
import Select from "../../../../../../Components/Select";
import { AdminRoomStatusType, IAdminRoom } from "../../../../../Store/roomsManagement/models";
import { editAdminFloorRoom, roomStatusList } from "../../../../../Store/roomsManagement";
import { useParams } from "react-router-dom";

interface Props {
  room: IAdminRoom;
}

export default function RoomStatus(props: Props) {
  const dispatch = useDispatch();
  const { locationId, floorId } = useParams<{ locationId: string ; floorId: string;}>();
  const { room } = props;
  const roomValue = room.status ? room.status : roomStatusList[0].value;

  const onChangeRoomStatus = (e: any) => {
    const status: AdminRoomStatusType = e.target.value;
    dispatch(editAdminFloorRoom({
      locationId,
      floorId,
      roomId: room.id,
      data: {
        status,
      },
    }));
  };

  return (
    <Select
      classes={{
        selectWrapper: styles.selectWrapper,
        materialSelect: styles.selectFullWidth,
        materialLabel: styles.selectLabel,
        text: styles.selectText,
        input: styles.rolePadding,
      }}
      id="desk-status"
      items={roomStatusList}
      label=""
      labelColor="gray"
      onChange={onChangeRoomStatus}
      overflowText
      showIconArrow={false}
      value={roomValue}
    />
  );
}