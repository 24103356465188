import getErrorMessageFromPayload from 'Store/Redux/Helpers/getErrorMessageFromPayload';
import L from 'leaflet';
// @ts-ignore
import overlap from 'polygon-overlap';
import { EDIT_FLOOR_SUCCESS, EditFloorSuccess } from 'App/Store/Locations/locationsDuck';
import { IFileWithMeta } from 'react-dropzone-uploader';
import { regexEmail } from 'Functions/helpers/regex';
import { t } from '@lingui/macro';
import {
  CHECK_DESK_RESERVATIONS,
  CHECK_DESK_RESERVATIONS_FAILURE,
  CHECK_DESK_RESERVATIONS_SUCCESS,
  CheckDeskReservation,
  CheckDeskReservationFailure,
  CheckDeskReservationSuccess,
  DELETE_DESK_FROM_FLOOR_SUCCESS,
  DELETE_MEETING_FROM_FLOOR,
  DELETE_MEETING_FROM_FLOOR_FAILURE,
  DELETE_MEETING_FROM_FLOOR_SUCCESS,
  DELETE_SECTION_FROM_FLOOR,
  DELETE_SECTION_FROM_FLOOR_FAILURE,
  DELETE_SECTION_FROM_FLOOR_SUCCESS,
  DeleteDeskFromFloorSuccess,
  DeleteMeetingFromFloor,
  DeleteMeetingFromFloorFailure,
  DeleteMeetingFromFloorSuccess,
  DeleteSectionFromFloor,
  DeleteSectionFromFloorFailure,
  DeleteSectionFromFloorSuccess,
  EDIT_MAP_FILE_SUCCESS,
  EditMapFileSuccess,
  GET_FLOOR_SCHEMA_SUCCESS,
  GetFloorSchemaSuccess,
  SAVE_MAP_DRAWINGS_FAILURE,
  SAVE_MAP_DRAWINGS_SUCCESS,
  SAVE_MAP_FILE_SUCCESS,
  SaveMapDrawingsFailure,
  SaveMapDrawingsSuccess,
  SaveMapFileSuccess,
} from '../floorMapApiRequestsDuck';
import {
  DrawState,
  DrawingStates,
  EditDeskList,
  FloorMapObject,
  FloorMapObjectTypeEnum,
  ILatLng,
  MapImageBoundsType,
  SetFloorMapDataRequest,
  UpdateAllDesksRequest,
  UpdateDeskRequest,
  UpdateMeetingRequest,
  UpdateSectionRequest,
} from './models';
import { DeskStatusEnum } from "./desk/models";
// import { EDIT_ADMIN_FLOOR_ROOM_SUCCESS, EditAdminFloorRoomSuccess } from "../roomsManagement";

export const SECTIONS_OVERLAP_ERROR = 'Sections can\'t overplap';
export const SECTION_OVERLAP_DESK_FROM_ANOTHER_SECTION_ERROR = 'Section can\'t overlap a desk that\'s inside another section';
export const MEETING_OVERLAP_ERROR = 'Meeting room can\'t overlap another desk, section or meeting room';

export const ADD_DESK = 'ADD_DESK';
export const ADD_SECTION = 'ADD_SECTION';
export const ADD_MEETING = 'ADD_MEETING';
export const CLEAR_STATE = 'CLEAR_STATE';
export const DELETE_DESK = 'DELETE_DESK';
export const DELETE_SECTION = 'DELETE_SECTION';
export const DELETE_MEETING = 'DELETE_MEETING';
export const RESET_CHANGES = 'RESET_CHANGES';
export const SET_DRAW_DESK_STATE = 'SET_DRAW_DESK_STATE';
export const SET_DRAW_SECTION_STATE = 'SET_DRAW_SECTION_STATE';
export const SET_DRAW_MEETING_STATE = 'SET_DRAW_MEETING_STATE';
export const SET_FLOOR_MAP_DATA = 'SET_FLOOR_MAP_DATA';
export const UPDATE_ALL_DESKS = 'UPDATE_ALL_DESKS';
export const UPDATE_DESK = 'UPDATE_DESK';
export const UPDATE_SECTION = 'UPDATE_SECTION';
export const UPDATE_MEETING = 'UPDATE_MEETING';

export interface AddDesk {
  type: typeof ADD_DESK;
  payload: FloorMapObject;
}

export interface AddSection {
  type: typeof ADD_SECTION;
  payload: FloorMapObject;
}

export interface AddMeeting {
  type: typeof ADD_MEETING;
  payload: FloorMapObject;
}

export interface ClearState {
  type: typeof CLEAR_STATE;
}

export interface DeleteDesk {
  type: typeof DELETE_DESK;
  payload: { deskId: string };
}

export interface DeleteSection {
  type: typeof DELETE_SECTION;
  payload: { sectionId: number };
}

export interface DeleteMeeting {
  type: typeof DELETE_MEETING;
  payload: { meetingId: number };
}

export interface ResetChanges {
  type: typeof RESET_CHANGES;
}

export interface SetFloorMapData {
  type: typeof SET_FLOOR_MAP_DATA;
  payload: SetFloorMapDataRequest;
}

export interface SetDrawDeskState {
  type: typeof SET_DRAW_DESK_STATE;
  payload: DrawState;
}

export interface SetDrawSectionState {
  type: typeof SET_DRAW_SECTION_STATE;
  payload: DrawState;
}

export interface SetDrawMeetingState {
  type: typeof SET_DRAW_MEETING_STATE;
  payload: DrawState;
}

export interface UpdateAllDesks {
  type: typeof UPDATE_ALL_DESKS;
  payload: UpdateAllDesksRequest;
}

export interface UpdateDesk {
  type: typeof UPDATE_DESK;
  payload: UpdateDeskRequest;
}

export interface UpdateSection {
  type: typeof UPDATE_SECTION;
  payload: UpdateSectionRequest;
}

export interface UpdateMeeting {
  type: typeof UPDATE_MEETING;
  payload: UpdateMeetingRequest;
}

export type Actions =
  | AddDesk
  | AddSection
  | AddMeeting
  | ClearState
  | EditFloorSuccess
  | DeleteDesk
  | DeleteSection
  | DeleteMeeting
  | GetFloorSchemaSuccess
  | DeleteSectionFromFloor
  | DeleteSectionFromFloorFailure
  | DeleteSectionFromFloorSuccess
  | DeleteDeskFromFloorSuccess
  | DeleteMeetingFromFloor
  | DeleteMeetingFromFloorFailure
  | DeleteMeetingFromFloorSuccess
  | ResetChanges
  | SaveMapFileSuccess
  | EditMapFileSuccess
  | CheckDeskReservation
  | CheckDeskReservationSuccess
  | CheckDeskReservationFailure
  | SaveMapDrawingsFailure
  | SaveMapDrawingsSuccess
  | SetDrawDeskState
  | SetDrawSectionState
  | SetDrawMeetingState
  | SetFloorMapData
  | UpdateAllDesks
  | UpdateDesk
  | UpdateSection
  | UpdateMeeting
  // | EditAdminFloorRoomSuccess

export interface State {
  activeDeskId?: string;
  activeSectionId?: number;
  activeMeetingId?: number;

  // map + desk list delete desk logic
  showDeleteDeskModal: boolean;
  deskHasReservation: boolean;
  deskReservationChecking: boolean;
  deskToDelete: FloorMapObject | null;
  // map + desk list delete section logic
  sectionDeleting: boolean;
  // map + delete meeting room logic
  meetingDeleting: boolean;

  desks: {
    [deskId: string]: FloorMapObject;
  };
  desksBackup: {
    [deskId: string]: FloorMapObject;
  };
  deskSize: {
    height: number;
    width: number;
  };
  drawDeskCount: number;
  drawingStates: DrawingStates;
  editDeskList: EditDeskList;
  errorMessage?: string;
  id: string;
  locationId: string;
  mapFileWithMeta: IFileWithMeta | null;
  mapSize: {
    height: number;
    width: number;
  };
  mapImageBounds: MapImageBoundsType | null,
  name: string;
  previewUrl: string;
  mapSizeToEdit: {
    height: number;
    width: number;
  };
  mapImageBoundsToEdit: MapImageBoundsType,
  fileWithMetaToEdit?: IFileWithMeta | null;
  sections: {
    [sectionId: number]: FloorMapObject;
  };
  sectionsBackup: {
    [sectionId: number]: FloorMapObject;
  };
  meetings: {
    [meetingId: number]: FloorMapObject;
  };
  meetingsBackup: {
    [meetingId: number]: FloorMapObject;
  };
  showSections: boolean;
  successMessage?: string;
}

const initialState: State = {
  activeDeskId: undefined,
  activeSectionId: undefined,
  activeMeetingId: undefined,
  deskSize: {
    height: 20,
    width: 20,
  },

  showDeleteDeskModal: false,
  deskHasReservation: false,
  deskReservationChecking: false,
  deskToDelete: null,
  sectionDeleting: false,
  meetingDeleting: false,

  desks: {},
  desksBackup: {},
  drawDeskCount: 0,
  drawingStates: {
    drawDesk: {
      active: false,
    },
    drawSection: {
      active: false,
    },
    drawMeeting: {
      active: false,
    },
  },
  editDeskList: {
    selectAllResources: {},
    selectAllStatus: DeskStatusEnum.Available,
    selectedDesks: {},
  },
  errorMessage: '',
  id: '',
  locationId: '',
  mapFileWithMeta: null,
  mapSize: {
    height: 0,
    width: 0,
  },
  mapImageBounds: null,
  name: '',
  previewUrl: '',
  mapSizeToEdit: {
    height: 0,
    width: 0,
  },
  mapImageBoundsToEdit: [[0, 0], [0, 0]],
  fileWithMetaToEdit: null,
  sections: {},
  sectionsBackup: {},
  meetings: {},
  meetingsBackup: {},
  showSections: true,
  successMessage: '',
};

export default function reducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ADD_DESK: {
      const desk = action.payload;
      let parentId = desk.parentId;

      if (!parentId) {
        const sectionsArray = Object.keys(state.sections).map(key => state.sections[parseInt(key)]);
        const coordinatesInLatLngBounds = desk.coordinatesInPoints.map(coordinate => L.CRS.Simple.pointToLatLng(L.point(coordinate.x, coordinate.y), 1));

        const sections = selectSectionsThatIntersectsBounds(sectionsArray, coordinatesInLatLngBounds);

        if (sections.length > 0) {
          const section = sections[0];

          if (typeof section.id === 'number') {
            parentId = section.id;
          }
        }
      }

      return {
        ...state,
        drawDeskCount: state.drawDeskCount + 1,
        desks: {
          ...state.desks,
          [desk.id]: {
            ...desk,
            parentId,
          },
        },
      };
    }

    case ADD_SECTION: {
      const newDesks = { ...state.desks };
      const sectionsArray = Object.keys(state.sections).map(sectionId => state.sections[parseInt(sectionId)]);
      const meetingsArray = Object.keys(state.meetings).map(meetingId => state.meetings[parseInt(meetingId)]);
      const preventOverlapSectionsArray = [...sectionsArray, ...meetingsArray];
      let hasSectionsThatIntersectsBounds;
      let newSections = { ...state.sections };
      let sectionOverlapDeskInsideAnotherSection = false;

      if (action.payload.latlngs) {
        // @ts-ignore
        action.payload.latlngs = action.payload.latlngs[0];
        // @ts-ignore
        hasSectionsThatIntersectsBounds = selectSectionsThatIntersectsBounds(preventOverlapSectionsArray, action.payload.latlngs).length > 0;
      }

      // Only add new section if doesn't intersects with other sections
      if (!hasSectionsThatIntersectsBounds) {
        // Check if the're desks inside the section being drawn and updates them correctly
        Object.keys(state.desks).forEach(deskId => {
          const desk = state.desks[deskId];
          const sections = selectSectionsThatIntersectsBounds([...preventOverlapSectionsArray, action.payload], desk.coordinatesInLatLngBounds);

          if (sections.length > 1) {
            sectionOverlapDeskInsideAnotherSection = true;
          } else {
            const section = sections[0];

            if (section && typeof section.id === 'number') {
              desk.parentId = section.id;
              desk.provisory = true;
              newDesks[desk.id] = desk;
            }
          }
        });

        newSections = {
          ...newSections,
          [action.payload.id]: action.payload,
        };
      }

      let errorMessage = '';

      if (hasSectionsThatIntersectsBounds) {
        errorMessage = SECTIONS_OVERLAP_ERROR;
      } else if (sectionOverlapDeskInsideAnotherSection) {
        errorMessage = SECTION_OVERLAP_DESK_FROM_ANOTHER_SECTION_ERROR;
      }

      return {
        ...state,
        errorMessage: errorMessage,
        desks: sectionOverlapDeskInsideAnotherSection ? state.desks : newDesks,
        sections: sectionOverlapDeskInsideAnotherSection ? state.sections : newSections,
      };
    }

    case ADD_MEETING: {
      const desksArray = Object.values(state.desks);
      const sectionsArray = Object.values(state.sections);
      const meetingsArray = Object.values(state.meetings);
      const preventOverlapSectionsArray = [...sectionsArray, ...meetingsArray, ...desksArray];
      let hasMeetingsThatIntersectsBounds;
      let newMeetings = { ...state.meetings };

      if (action.payload.latlngs) {
        // @ts-ignore
        action.payload.latlngs = action.payload.latlngs[0];
        // @ts-ignore
        hasMeetingsThatIntersectsBounds = selectSectionsThatIntersectsBounds(preventOverlapSectionsArray, action.payload.latlngs).length > 0;
      }

      // Only add new section if doesn't intersects with other sections
      if (!hasMeetingsThatIntersectsBounds) {
        newMeetings = {
          ...newMeetings,
          [action.payload.id]: action.payload,
        };
      }

      let errorMessage = '';

      if (hasMeetingsThatIntersectsBounds) {
        errorMessage = MEETING_OVERLAP_ERROR;
      }

      return {
        ...state,
        errorMessage: errorMessage,
        meetings: newMeetings,
      };
    }

    case CLEAR_STATE: {
      return {
        ...initialState,
      };
    }

    case EDIT_FLOOR_SUCCESS: {
      return {
        ...state,
        name: action.payload.data.result.data.floorName,
      };
    }

    case DELETE_DESK: {
      delete state.desks[action.payload.deskId];

      return {
        ...state,
      };
    }

    case DELETE_SECTION: {
      delete state.sections[action.payload.sectionId];

      return {
        ...state,
      };
    }

    case DELETE_MEETING: {
      delete state.meetings[action.payload.meetingId];

      return {
        ...state,
      };
    }

    case DELETE_SECTION_FROM_FLOOR: {
      return {
        ...state,
        sectionDeleting: true,
      };
    }
    case DELETE_SECTION_FROM_FLOOR_FAILURE: {
      return {
        ...state,
        sectionDeleting: false,
      };
    }
    case DELETE_SECTION_FROM_FLOOR_SUCCESS: {
      delete state.sections[action.sectionId];

      return {
        ...state,
        successMessage: t`Section deleted successfully`,
        sectionDeleting: false,
      };
    }

    case DELETE_MEETING_FROM_FLOOR: {
      return {
        ...state,
        sectionDeleting: true,
      };
    }
    case DELETE_MEETING_FROM_FLOOR_FAILURE: {
      return {
        ...state,
        meetingDeleting: false,
      };
    }
    case DELETE_MEETING_FROM_FLOOR_SUCCESS: {
      delete state.meetings[action.meetingId];

      return {
        ...state,
        successMessage: t`Meeting room deleted successfully`,
        meetingDeleting: false,
      };
    }

    case DELETE_DESK_FROM_FLOOR_SUCCESS: {
      delete state.desks[action.deskId];

      return {
        ...state,
        successMessage: t`Desk deleted successfully`,
      };
    }

    case CHECK_DESK_RESERVATIONS: {
      return {
        ...state,
        deskReservationChecking: true, //
        deskToDelete: action.payload.request.data?.desk || null, // use deskToDelete data for delete confirmation desk popup
      };
    }
    case CHECK_DESK_RESERVATIONS_FAILURE: {
      return {
        ...state,
        deskReservationChecking: false,
      };
    }
    case CHECK_DESK_RESERVATIONS_SUCCESS: {
      return {
        ...state,
        showDeleteDeskModal: true,
        deskReservationChecking: false,
        deskHasReservation: action.payload.data.result.data.isReserved,
      };
    }

    case GET_FLOOR_SCHEMA_SUCCESS: {
      const { drawings, mapUrl, floorId, floorName: name, size, mapImageBounds } = action.payload.data.result.data;

      const desks: { [deskId: string]: FloorMapObject } = {};
      const sections: { [sectionId: number]: FloorMapObject } = {};
      const meetings: { [sectionId: number]: FloorMapObject } = {};

      let desksCount = 0;

      drawings.forEach(draw => {
        const {
          approvers,
          coordinates,
          deskId,
          fill,
          line,
          name,
          owners,
          groups,
          resourceIds: resourceIdsArray,
          sectionId,
          roomId,
          status,
          type,
          amenityIds: amenityIdsArray,
          description,
          reservationDayLimit,
        } = draw;

        const coordinatesInLatLngBounds: L.LatLng[] = [];
        const approver1 = approvers ? approvers[0] : undefined;
        const approver2 = approvers ? approvers[1] : undefined;

        coordinates.forEach(coordinate => {
          const point = L.point(coordinate.x, coordinate.y);
          const latLng = L.CRS.Simple.pointToLatLng(point, 1);

          coordinatesInLatLngBounds.push(latLng);
        });


        if (type === FloorMapObjectTypeEnum.Desk && deskId) {
          desksCount += 1;

          const resourceIds: { [resourceId: string]: string } = {};

          if (resourceIdsArray) {
            resourceIdsArray.forEach(resourceId => {
              resourceIds[resourceId] = resourceId;
            });
          }

          desks[deskId] = {
            approvers: {
              approver1: {
                email: approver1 ? approver1.email : '',
              },
              approver2: {
                email: approver2 ? approver2.email : '',
              },
            },
            // @ts-ignore
            coordinatesInLatLngBounds,
            coordinatesInPoints: coordinates,
            fill,
            id: deskId,
            isAvailable: true,
            latlngs: undefined,
            line,
            name,
            owners,
            groups,
            parentId: sectionId ? sectionId : undefined,
            provisory: false,
            hasError: false,
            resourceIds: resourceIds,
            saved: true,
            status: status ? status : DeskStatusEnum.Available,
            type,
          };
        } else if (type === FloorMapObjectTypeEnum.Section && sectionId) {
          sections[sectionId] = {
            owners,
            groups,
            coordinatesInLatLngBounds,
            coordinatesInPoints: coordinates,
            fill,
            id: sectionId,
            isAvailable: true,
            line,
            name,
            provisory: false,
            hasError: false,
            resourceIds: {},
            saved: true,
            status: DeskStatusEnum.Available,
            type,
          };
        } else if (type === FloorMapObjectTypeEnum.Meeting && sectionId) {
          const amenityIds: { [amenityId: string]: string } = {};

          if (amenityIdsArray) {
            amenityIdsArray.forEach(amenityId => {
              amenityIds[amenityId] = amenityId;
            });
          }

          meetings[sectionId] = {
            owners,
            groups,
            coordinatesInLatLngBounds,
            coordinatesInPoints: coordinates,
            fill,
            id: sectionId,
            isAvailable: true,
            line,
            name,
            provisory: false,
            hasError: false,
            amenityIds: amenityIds,
            resourceIds: {},
            saved: true,
            status: status ? status : DeskStatusEnum.Available,
            type,
            roomId,
            capacity: draw.capacity,
            availableServices: draw.availableServices || [],
            description,
            reservationDayLimit,
          };
        }
      });

      return {
        ...state,
        desks,
        desksBackup: desks,
        drawDeskCount: desksCount,
        id: floorId,
        previewUrl: mapUrl,
        mapImageBounds,
        mapSize: {
          height: parseInt(size.height),
          width: parseInt(size.width),
        },
        name,
        sections,
        sectionsBackup: sections,
        meetings,
        meetingsBackup: meetings,
      };
    }

    // Check after BE update
    // case EDIT_ADMIN_FLOOR_ROOM_SUCCESS: { // updated status for map view from room list action
    //   const room = action.payload.data.result.data;
    //   const newMeetings: { [sectionId: number]: FloorMapObject } = {...state.meetings};
    //   const updatedMeeting = Object.values(newMeetings).find(meeting => meeting.roomId = room.id);
    //
    //   if (updatedMeeting && updatedMeeting.id) {
    //     // @ts-ignore // its correct but AdminRoomStatusType not assigned to DeskStatus - need to be refactored
    //     newMeetings[updatedMeeting.id].status = room.status;
    //   }
    //
    //   return {
    //     ...state,
    //     meetings: newMeetings,
    //     meetingsBackup: newMeetings,
    //   };
    // }

    case RESET_CHANGES: {
      return {
        ...state,
        desks: state.desksBackup,
        sections: state.sectionsBackup,
        meetings: state.meetingsBackup,
        successMessage: t`Discarded unsaved changes`,
      };
    }

    case SAVE_MAP_FILE_SUCCESS: {
      const { floorName, id, locationId } = action.payload.data.result.data;

      return {
        ...state,
        id,
        locationId,
        name: floorName,
      };
    }

    case EDIT_MAP_FILE_SUCCESS: {
      const { url } = action.payload.data.result.data;

      return {
        ...state,
        previewUrl: url,
        successMessage: t`Map file updated`,
      };
    }

    case SAVE_MAP_DRAWINGS_FAILURE: {
      return {
        ...state,
        errorMessage: getErrorMessageFromPayload({ payload: action.payload, fallbackMessage: t`There was an error saving map drawings. Please try again.` }),
      };
    }

    case SAVE_MAP_DRAWINGS_SUCCESS: {
      const desks: Record<string, FloorMapObject> = {};
      const sections: Record<string, FloorMapObject> = {};
      const meetings: Record<string, FloorMapObject> = {};

      for (const [deskId, desk] of Object.entries(state.desks)) {
        desks[deskId] = { ...desk, provisory: false, saved: true };
      }

      for (const [sectionId, section] of Object.entries(state.sections)) {
        sections[sectionId] = { ...section, provisory: false, saved: true };
      }

      for (const [meetingId, meeting] of Object.entries(state.meetings)) {
        meetings[meetingId] = { ...meeting, provisory: false, saved: true };
      }

      return {
        ...state,
        desks,
        desksBackup: desks,
        sections,
        sectionsBackup: sections,
        meetings,
        meetingsBackup: meetings,
        successMessage: t`Changes saved`,
      };
    }

    case SET_DRAW_DESK_STATE: {
      return {
        ...state,
        drawingStates: {
          drawDesk: action.payload,
          drawMeeting: {
            active: false,
          },
          drawSection: {
            active: false,
          },
        },
      };
    }

    case SET_DRAW_SECTION_STATE: {
      return {
        ...state,
        drawingStates: {
          drawDesk: {
            active: false,
          },
          drawMeeting: {
            active: false,
          },
          drawSection: action.payload,
        },
      };
    }

    case SET_DRAW_MEETING_STATE: {
      return {
        ...state,
        drawingStates: {
          drawDesk: {
            active: false,
          },
          drawMeeting: action.payload,
          drawSection: {
            active: false,
          },
        },
      };
    }

    case SET_FLOOR_MAP_DATA: {
      return {
        ...state,
        ...action.payload,
        editDeskList: {
          ...state.editDeskList,
          ...action.payload.editDeskList,
        },
      };
    }

    case UPDATE_ALL_DESKS: {
      const { resources } = action.payload.updateDeskProperties;
      const newDesks = { ...state.desks };

      Object.keys(state.desks).forEach(deskId => {
        let desk = state.desks[deskId];
        const deskSelected = Boolean(state.editDeskList.selectedDesks[deskId]);

        if (deskSelected) {
          const resourceIds: { [resourceId: string]: string } = {};

          if (resources) {
            Object.keys(resources).forEach(resourceId => {
              resourceIds[resourceId] = resourceId;
            });
          }

          // This property is deleted to avoid spreading it incorrectly, since it's already used above on resourceIds
          delete action.payload.updateDeskProperties.resources;

          desk = {
            ...desk,
            ...action.payload.updateDeskProperties,
            provisory: true,
            resourceIds,
          };
        }

        newDesks[deskId] = desk;
      });

      return {
        ...state,
        desks: newDesks,
      };
    }

    case UPDATE_DESK: {
      const { deskId, updateDeskProperties } = action.payload;

      const resourceIds = updateDeskProperties.resources ? { ...updateDeskProperties.resources } : null;
      // This property is deleted to avoid spreading it incorrectly, since it's already used above on resourceIds
      delete updateDeskProperties.resources;

      return {
        ...state,
        desks: {
          ...state.desks,
          [deskId]: {
            ...state.desks[deskId],
            ...updateDeskProperties,
            provisory: true,
            resourceIds: resourceIds ?? state.desks[deskId].resourceIds,
          },
        },
      };
    }

    case UPDATE_SECTION: {
      return {
        ...state,
        sections: {
          ...state.sections,
          [action.payload.sectionId]: {
            ...state.sections[action.payload.sectionId],
            ...action.payload.section,
            provisory: true,
          },
        },
      };
    }

    case UPDATE_MEETING: {
      const { meeting, meetingId } = action.payload;

      const amenityIds = meeting.amenities ? { ...meeting.amenities } : null;
      // This property is deleted to avoid spreading it incorrectly, since it's already used above on amenityIds
      delete meeting.resources;

      return {
        ...state,
        meetings: {
          ...state.meetings,
          [action.payload.meetingId]: {
            ...state.meetings[action.payload.meetingId],
            ...action.payload.meeting,
            provisory: true,
            amenityIds: amenityIds ?? state.meetings[meetingId].amenityIds,
          },
        },
      };
    }

    default:
      return state;
  }
}

// Actions
export function addDesk(data: FloorMapObject): AddDesk {
  return {
    type: ADD_DESK,
    payload: data,
  };
}

export function addSection(data: FloorMapObject): AddSection {
  return {
    type: ADD_SECTION,
    payload: data,
  };
}

export function addMeeting(data: FloorMapObject): AddMeeting {
  return {
    type: ADD_MEETING,
    payload: data,
  };
}

export function clearFloorMapState(): ClearState {
  return {
    type: CLEAR_STATE,
  };
}

export function deleteDesk(deskId: string): DeleteDesk {
  return {
    type: DELETE_DESK,
    payload: { deskId },
  };
}

export function deleteSection(sectionId: number): DeleteSection {
  return {
    type: DELETE_SECTION,
    payload: { sectionId },
  };
}

export function deleteMeeting(meetingId: number): DeleteMeeting {
  return {
    type: DELETE_MEETING,
    payload: { meetingId },
  };
}

/**
 * Deletes all sections and desks with provisory: true
 */
export function resetChanges(): ResetChanges {
  return {
    type: RESET_CHANGES,
  };
}

/**
 * Set draw desk state and inactivates draw section state
 */
export function setDrawDeskState(data: DrawState): SetDrawDeskState {
  return {
    type: SET_DRAW_DESK_STATE,
    payload: data,
  };
}

/**
 * Set draw section state and inactivates draw desk state
 */
export function setDrawSectionState(data: DrawState): SetDrawSectionState {
  return {
    type: SET_DRAW_SECTION_STATE,
    payload: data,
  };
}

/**
 * Set draw meeting state and inactivates draw desk state
 */
export function setDrawMeetingState(data: DrawState): SetDrawMeetingState {
  return {
    type: SET_DRAW_MEETING_STATE,
    payload: data,
  };
}

export function setFloorMapData(data: SetFloorMapDataRequest): SetFloorMapData {
  return {
    type: SET_FLOOR_MAP_DATA,
    payload: data,
  };
}

export function updateAllDesks(data: UpdateAllDesksRequest): UpdateAllDesks {
  return {
    type: UPDATE_ALL_DESKS,
    payload: data,
  };
}

export function updateDesk(data: UpdateDeskRequest): UpdateDesk {
  return {
    type: UPDATE_DESK,
    payload: data,
  };
}

export function updateSection(data: UpdateSectionRequest): UpdateSection {
  return {
    type: UPDATE_SECTION,
    payload: data,
  };
}

export function updateMeeting(data: UpdateMeetingRequest): UpdateMeeting {
  return {
    type: UPDATE_MEETING,
    payload: data,
  };
}

// Selectors
/**
 * Check if all desks that are marked with Status: Approval required, have at least one approver
 */
export function selectAreDesksApproversValid(state: State): { valid: boolean } {
  const { desks } = state;

  let valid = true;

  const desksArray = Object.keys(desks).map(deskId => desks[deskId]);

  desksArray.forEach(desk => {
    if (desk.status === DeskStatusEnum.ApprovalRequired) {
      const { approvers } = desk;
      const emailValidator = new RegExp(regexEmail);

      const approver1 = approvers?.approver1.email ? emailValidator.test(approvers?.approver1.email) : false;
      const approver2 = approvers?.approver2.email ? emailValidator.test(approvers?.approver2.email) : false;

      if (!approver1 && !approver2) {
        valid = false;
      }
    }
  });

  return {
    valid,
  };
}

/**
 * Receives a desk name, the deskId to be updated and check if it's valid.
 * 
 * A desk name will be valid if there're no other desks with that name or if the existings
 * desk with that name is it's the desk own name
 */
export function selectIsDeskNameValid(state: State, deskName: string, updatingDeskId: string): boolean {
  // We use some so that if there's a desk with the same name stops the iteration
  // Because of that, we have to use the contrary of the value found.
  const valid = !Object.keys(state.desks).some(deskId => {
    const desk = state.desks[deskId];

    const notSameDesk = desk.id !== updatingDeskId;
    const sameName = desk.name.toLowerCase().trim() === deskName.toLowerCase().trim();

    return sameName && notSameDesk;
  });

  return valid;
}

/**
 * If desk has a section, returns it
 */
export function selectDeskSection(state: State, deskId: string): FloorMapObject | undefined {
  let section;
  const desk = state.desks[deskId];

  if (desk.parentId) {
    section = state.sections[desk.parentId];
  }

  return section;
}

/**
 * Check if there're any unsaved changes. This means, any section or object with provisory: true
 */
export function selectHasChanges(state: State): boolean {
  const desksArray = Object.keys(state.desks).map(deskId => state.desks[deskId]);
  const sectionsArray = Object.keys(state.sections).map(sectionId => state.sections[parseInt(sectionId)]);
  const meetingsArray = Object.keys(state.meetings).map(sectionId => state.meetings[parseInt(sectionId)]);

  const desksHasChanges = desksArray.some(desk => desk.provisory);
  const sectionsHasChanges = sectionsArray.some(section => section.provisory);
  const meetingsHasChanges = meetingsArray.some(section => section.provisory);

  return desksHasChanges || sectionsHasChanges || meetingsHasChanges;
}

/**
 * Check if there're any error after changes map drawings. This means, any section or object with hasError: true
 */
export function selectHasErrors(state: State): boolean {
  const desksArray = Object.keys(state.desks).map(deskId => state.desks[deskId]);
  const sectionsArray = Object.keys(state.sections).map(sectionId => state.sections[parseInt(sectionId)]);
  const meetingsArray = Object.keys(state.meetings).map(sectionId => state.meetings[parseInt(sectionId)]);

  const desksHasErrors = desksArray.some(desk => desk.hasError);
  const sectionsHasErrors = sectionsArray.some(section => section.hasError);
  const meetingsHasErrors = meetingsArray.some(section => section.hasError);

  return desksHasErrors || sectionsHasErrors || meetingsHasErrors;
}

/**
 * Giving a specific bounds, check if there are any secktions that intersects with it. 
 */
export function selectSectionsThatIntersectsBounds(sections: FloorMapObject[], coordinates: ILatLng[]): FloorMapObject[] {
  const sectionsIntersectBounds: FloorMapObject[] = [];

  sections.forEach(section => {
    if (section.coordinatesInLatLngBounds) {
      const p0 = section.coordinatesInLatLngBounds.map(coordinate => [coordinate.lng, coordinate.lat]);
      const p1 = coordinates.map(coordinate => [coordinate.lng, coordinate.lat]);

      const overlaps = overlap(p0, p1);

      if (overlaps) {
        sectionsIntersectBounds.push(section);
      }
    }
  });

  return sectionsIntersectBounds;
}