import React, { useState } from "react";
import { ConfirmationDialogProps } from "./types";
import { Box, Button, Dialog, Typography } from "@mui/material";
import { t } from "@lingui/macro";

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = (props) => {
  const { title, description, Trigger, onConfirm } = props;
  const [isOpen, setIsOpen] = useState(false);

  const handleConfirmClick = () => {
    setIsOpen(false);
    onConfirm?.();
  };

  return (
    <>
      <Trigger onClick={() => setIsOpen(true)} />
      <Dialog onClose={() => setIsOpen(false)} open={isOpen}>
        <Box p={2}>
          <Box mb={2}>
            <Typography fontWeight="600" mb={1}>{title}</Typography>
            <Typography fontSize={14}>{description}</Typography>
          </Box>
          <Box display="flex" justifyContent="flex-end">
            <Button onClick={() => setIsOpen(false)}>{t`No`}</Button>
            <Button onClick={handleConfirmClick} sx={{ ml: 2 }} variant="contained">{t`Yes`}</Button>
          </Box>
        </Box>
      </Dialog>
    </>
  );
};
