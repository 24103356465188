import LocationDetailsHeader from 'Admin/Components/Header/LocationDetailsHeader';
import { ToastProvider } from 'components';
import { FloorsList } from './floors-list';

export default function Floors() {
  return (
    <>
      <LocationDetailsHeader />
      <ToastProvider>
        <FloorsList />
      </ToastProvider>
    </>
  );
}
