import styles from "../..//styles.module.scss";
import { Trans } from "@lingui/macro";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Box from "../../../../../Components/Box";

export default function RoomsTableHeader() {

  return (
    <TableHead classes={{ root: styles.tableHead }}>
      <TableRow classes={{ root: styles.tableRow }}>
        <TableCell classes={{ root: styles.tableCell }} padding="none">
          <Box alignItems="center" display="flex">
            <Trans>
              Room ID
            </Trans>
          </Box>
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }} padding="none">
          <Trans>
            Room name
          </Trans>
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }} padding="none">
          <Trans>
            Status
          </Trans>
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }} padding="none">
          <Trans>
            Amenities
          </Trans>
        </TableCell>

        <TableCell classes={{ root: styles.tableCell }} padding="none">
          <Trans>
            Capacity
          </Trans>
        </TableCell>

        <TableCell classes={{root: styles.tableCell}} padding="none">
          {/* options menu cell */}
        </TableCell>
      </TableRow>
    </TableHead>
  );
}