import { t } from "@lingui/macro";
import { ReservationsList } from "components";
import { addDays, startOfToday } from "date-fns";
import { useDelegatedId } from "hooks";
import React from "react";
import { ReservationStatus, ReservationType } from "store";

export const HomeUpcomingRoute: React.FC = () => {
  const delegatedId = useDelegatedId();
  const types = [ReservationType.DESK, ReservationType.VISIT];

  if (!delegatedId) {
    types.push(ReservationType.ROOM);
  }

  return (
    <ReservationsList
      filter={{
        "status": `$in:${ReservationStatus.BOOKED},${ReservationStatus.FAILED}`,
        "schedule.entries.startDate": `$gt:${addDays(startOfToday(), 1).toISOString()}`,
        "type": `$in:${types.join(",")}`,
      }}
      include={["attendees", "desk", "room", "schedule", "schedule.entries", "floor", "floor.location", "children", "children.parkingSpot"]}
      limit={15}
      modifiable={!delegatedId}
      orderBy={["desc:status", "asc:schedule.entries.startDate"]}
      placeholder={[
        t`You have no booking in your list for the selected date.`,
        t`Tap on Create Booking button to create or try a new date.`,
      ]}
      userId={delegatedId}
    />
  );
};
