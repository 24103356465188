import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../../Store/Redux/store";

import Box from "../../../Components/Box";
import AddFloorHeader from "../../Components/Header/AddFloorHeader";
import RoomsTable from "./RoomsTable";
import AddFloorDataUpdate from "../../Components/Header/AddFloorHeader/Components/AddFloorDataUpdate";
import AddFloorActionMenu from "../../Components/Header/AddFloorHeader/Components/AddFloorActionMenu";
import AddFloorRoomData from "../../Components/Header/AddFloorHeader/Components/AddFloorRoomData";
import { ErrorRoomsManagementSnackbar, SuccessRoomsManagementSnackbar } from "./SnackBars";
import CustomPagination from "../../../Components/CustomPagination";
import {
  getAdminFloorRooms,
  initFiltersData,
  setAdminFloorRoomsData,
} from "../../Store/roomsManagement";

export default function RoomsManagement() {
  const dispatch = useDispatch();
  const { locationId, floorId } = useParams<{ locationId: string ; floorId: string;}>();
  const { floorRooms, totalCount, limit, filters } = useTypedSelector(state => state.roomsManagement);
  const { search } = filters;
  const totalPages = Math.ceil(totalCount / limit);

  const resetFiltersData = () => {
    dispatch(setAdminFloorRoomsData({
      filters: initFiltersData,
    }));
  };

  function getRoomsData(pageNumber?: number) {
    const page = pageNumber ? pageNumber : 1;

    dispatch(getAdminFloorRooms({
      locationId,
      floorId,
      filters: { page, limit, search },
    }));
  }

  const onChangePage = (page: number) => {
    getRoomsData(page);
  };

  useEffect(() => {
    resetFiltersData();
  }, []);

  useEffect(() => {
    getRoomsData();
  }, [search]);

  return (
    <>
      <SuccessRoomsManagementSnackbar />
      <ErrorRoomsManagementSnackbar />

      <AddFloorHeader>
        <AddFloorDataUpdate />
        <AddFloorRoomData />
        <AddFloorActionMenu />
      </AddFloorHeader>

      <Box>
        <RoomsTable rooms={floorRooms} />
      </Box >
      {floorRooms?.length ?
        <Box padding="100px 0 50px">
          <CustomPagination
            count={totalPages}
            onChange={onChangePage}
          />
        </Box> : null
      }
    </>
  );
}