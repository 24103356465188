import Box from 'Components/Box';
import Button from 'Components/Button';
import format from 'date-fns/format';
import isSameDay from 'date-fns/isSameDay';
import Text from 'Components/Text';
import useSnackbar from 'Components/Snackbar/useSnackbar';
import { CreateWeeklyBookingModel } from 'App/Store/Bookings/bookingDuck/models';
import { getClosestAvailableDay } from '../../../Functions/Helpers';
import { Trans, t } from '@lingui/macro';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useTypedSelector } from 'Store/Redux/store';
import {
  createBooking,
  setBookingsData,
} from 'App/Store/Bookings/bookingDuck';
import { isAnotherUserSelectedByExecutiveAssistant } from "../../../Store/Users/executiveAssistant/helpers";
import styles from './styles.module.scss';
import moment from 'moment';
import { Tooltip } from '@material-ui/core';
import { ApiTag, togetherApi } from 'store';

/**
 * Calendar component to select date and time ranges.
 */

interface BookingDetailsProps {
  type: 'support' | 'desk' | 'meeting';
}

export default function BookingDetails(props: BookingDetailsProps) {
  const dispatch = useDispatch();
  const [openSnackbar] = useSnackbar();
  const { booking: bookingDuck, locations: locationsDuck, config, createNewBooking, profile, executiveAssistant } = useTypedSelector(state => state);
  const selectedDesk = useTypedSelector(({ availableDesks: { availableDesks }, createNewBooking: { deskId } }) => {
    return deskId ? availableDesks[deskId] : undefined;
  });
  const {
    bookingType,
    dateFrom: reduxDateFrom,
    dateTo: reduxDateTo,
    deskId,
    floorId,
    locationId,
    parkingSpotId: bookingParkingSpotId,
    timeFrom,
    timeTo,
    bookingCreated,
    weeklySlots,
  } = createNewBooking;

  useEffect(() => {
    const { error, errorObject } = bookingDuck;

    if (error) {
      const errors = [error];

      if (errorObject) {
        if (errorObject.code === 1_001_012) {
          const location = locationsDuck.locations.find(({ id }) => id === locationId);
          const error = t({
            message: "User already has a reservation for selected dates at \"{0}\".",
            values: { 0: location?.locationName },
          });

          errors.push(error);
        } else {
          errors.push(`${errorObject.statusCode}`, errorObject.message);
        }
      }

      openSnackbar({
        onClose: () => dispatch(setBookingsData({ error: '' })),
        text: errors.join(" "),
        type: 'error',
      });
    }
  }, [bookingDuck.error, bookingDuck.errorObject]);

  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (bookingCreated || bookingDuck.error) {
      setIsSubmitting(false); // enable book button after action has processed
    }
  }, [bookingCreated, bookingDuck.error]);

  const dateFrom = getClosestAvailableDay(reduxDateFrom, locationsDuck.locationDisabledDays);
  const dateTo = getClosestAvailableDay(reduxDateTo, locationsDuck.locationDisabledDays);
  const userId = isAnotherUserSelectedByExecutiveAssistant(profile, executiveAssistant)
    ? executiveAssistant?.selectedUser?.id
    : undefined;

  const typeTexts = {
    'desk': t`Book Desk`,
    'support': t`Book an Appointment`,
    'meeting': t`Book Meeting room`,
  };

  const bookButtonText = typeTexts[props.type];

  let dateFromDay;
  let dateToDay;
  let dateFromMonth;
  let dateToMonth;
  let timeFromFormated = '';
  let timeToFormated = '';

  if (dateFrom && dateTo && timeFrom && timeTo) {
    dateFromDay = moment(dateFrom).format('DD');
    dateToDay = moment(dateTo).format('DD');
    dateFromMonth = moment(dateFrom).format('MMM');
    dateToMonth = moment(dateTo).format('MMM');
    timeFromFormated = moment(timeFrom).format('HH:mm');
    timeToFormated = moment(timeTo).format('HH:mm');
  }

  const bookDesk = () => {
    switch (bookingType) {
      case "custom": {
        if (locationId && deskId && dateFrom && dateTo && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          setIsSubmitting(true);
          dispatch(createBooking({
            custom: {
              dateFrom: format(new Date(dateFrom), 'yyyy-MM-dd'),
              dateTo: format(new Date(dateTo), 'yyyy-MM-dd'),
              timeFrom: timeFromFormatted,
              timeTo: timeToFormatted,
            },
            deskId,
            userId,
            parkingSpotId: bookingParkingSpotId || undefined,
          }, locationId));
        }
        break;
      }
      case "daily": {
        if (locationId && deskId && timeFrom && timeTo) {
          const timeFromFormatted = timeFrom ? format(new Date(timeFrom), 'HH:mm') : '';
          const timeToFormatted = timeTo ? format(new Date(timeTo), 'HH:mm') : '';

          setIsSubmitting(true);
          dispatch(createBooking({
            daily: {
              timeFrom: timeFromFormatted,
              timeTo: timeToFormatted,
            },
            deskId,
            userId,
            parkingSpotId: bookingParkingSpotId || undefined,
          }, locationId));
        }
        break;
      }
      case "weekly": {
        if (locationId && deskId && timeFrom && weeklySlots) {
          const weekly: CreateWeeklyBookingModel[] = weeklySlots
            .filter(({ isSelected, timeFrom, timeTo }) => isSelected && timeFrom && timeTo)
            .map(({ index, isSelected: selected, day: weekDay, timeFrom, timeTo }) => ({
              index,
              selected,
              weekDay,
              timeFrom: timeFrom ? format(new Date(timeFrom), "HH:mm") : new Date(),
              timeTo: timeTo ? format(new Date(timeTo), "HH:mm") : new Date(),
            }));
          
          if (weekly.length) {
            setIsSubmitting(true);
            dispatch(createBooking({
              weekly,
              deskId,
              userId,
              parkingSpotId: bookingParkingSpotId || undefined,
            }, locationId));
          }
        }
        break;
      }
      default:
        break;
    }

    dispatch(togetherApi.util.invalidateTags([{ type: ApiTag.RESERVATION }, { type: ApiTag.USER_HOME_COUNTS }]));
  };

  const hasDateAndTimeFunction = (): boolean => {
    let isValid = false;

    switch (bookingType) {
      case 'custom': {
        isValid = Boolean(dateFrom && dateTo && timeFrom && timeTo);
        break;
      }
      case 'daily': {
        isValid = Boolean(timeFrom && timeTo && timeFrom && timeTo);
        break;
      }
      case 'weekly': {
        isValid = weeklySlots.some(({ isSelected }) => isSelected);

        for (const weeklySlot of weeklySlots) {
          if (!isValid) {
            continue;
          }

          if (weeklySlot.isSelected && (!weeklySlot.timeFrom || !weeklySlot.timeTo)) {
            isValid = false;
          }
        }

        break;
      }
    }

    return isValid;
  };

  const datesAreSameDay = dateFrom && dateTo ? isSameDay(new Date(dateFrom), new Date(dateTo)) : true;
  const hasDateAndTime = hasDateAndTimeFunction();
  const allStepsCompleted = Boolean(deskId && floorId && locationId && hasDateAndTime);
  const boxEnabling = Boolean(locationId && hasDateAndTime);
  const selectedWeekDays = weeklySlots.filter(({ isSelected }) => isSelected).map(({ day }) => day);  
  const isMeetingBooking = props.type === 'meeting';
  const bookingBoxClass = isMeetingBooking ? styles.boxBooking: styles.box;
  const bookingButtonClass = isMeetingBooking ? styles.btnBooking: styles.btn;

  return (
    <Box
      alignItems="center"
      backgroundColor={config.theme.primary}
      borderRadius={20}
      display="flex"
      justifyContent="between"
      padding={22}
      style={{
        backgroundColor: config.theme.primary,
        opacity: !boxEnabling ? '0.5' : '1',
      }}
    >      
      {!isMeetingBooking ?
        <Box>
          {hasDateAndTime ? (
            <Box>
              <Text color="white" size="md" weight="semi-bold">
                <Trans>
                  Date and time
                </Trans>

                <br />

                {bookingType === 'custom' &&
                  <>
                    {dateFromMonth} {dateFromDay}{!datesAreSameDay ? ` - ${dateToMonth} ${dateToDay}` : ''}
                    &nbsp;{timeFromFormated}-{timeToFormated}
                  </>
                }

                {bookingType === 'daily' &&
                  <>
                    Daily {timeFromFormated}-{timeToFormated}
                  </>
                }

                {bookingType === 'weekly' &&
                  <>
                    {selectedWeekDays.map((day, index) => {
                      const isLast = index + 1 === selectedWeekDays.length;

                      return `${day}${isLast ? '' : '-'}`;
                    })}
                  </>
                }
              </Text>
            </Box>
          ) : (
            <Text color="white" size="md" weight="semi-bold">
              <Trans>
                Date and time <br />
                Not selected
              </Trans>
            </Text>
          )}
      </Box>:
      null 
      }           
      
      <Box
        className={bookingBoxClass}
        dataTestId={!allStepsCompleted || isSubmitting ? "bookDeskButtonDisabled" : "bookDeskButtonEnabled"}
      > 
        <Tooltip title={selectedDesk?.isBooked ? t`Sorry, this desk is not available at selected time` : ""}>
          <span>
            <Button
              aria-label={bookButtonText}          
              className={bookingButtonClass}
              disabled={!allStepsCompleted || isSubmitting || selectedDesk?.isBooked}
              name={bookButtonText}
              onClick={bookDesk}
              size="sm"
              type="white"
            >
              {bookButtonText}
            </Button>        
          </span>
        </Tooltip>
      </Box>
    </Box>
  );
}
