import { ToastProvider } from "../toast-provider";
import React from "react";
import { Container } from "./container";

export const PageContainer: React.FC<React.PropsWithChildren<{}>> = (props) => {
  const { children } = props;

  return (
    <ToastProvider>
      <Container>
        {children}
      </Container>
    </ToastProvider>
  );
};