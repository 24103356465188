import AddFloorHeader from 'Admin/Components/Header/AddFloorHeader';
import Box from 'Components/Box';
import UploadFloorMap from './Components/UploadFloorMap';
import Map from 'Admin/Pages/FloorMap/Map';
import styles from './styles.module.scss';
import useSnackbar from 'Components/Snackbar/useSnackbar';
import { getAttributes } from 'Admin/Store/attributes';
import { getFloorSchema, setFloorMapApiRequestsData } from 'Admin/Store/floorMapApiRequestsDuck';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';
import AddFloorDataUpdate from "../../Components/Header/AddFloorHeader/Components/AddFloorDataUpdate";
import AddFloorActionMenu from "../../Components/Header/AddFloorHeader/Components/AddFloorActionMenu";
import FloorMapControls from "./Controls";
import DrawingDetails from "./Map/DrawingDetails";

/**
 * Floor map. Create/edit map. Edit sections and desks.
 *
 * reference: https://projects.invisionapp.com/d/main?origin=v7#/console/21248637/449340369/preview?scrollOffset=2540
 *
 * OwnedBy: Bruno
 */
export default function FloorMap() {
  const dispatch = useDispatch();
  const { floorId, locationId } = useParams<{ floorId: string; locationId: string }>();
  const [open] = useSnackbar();
  const { adminFloorMap, adminFloorMapApiRequests } = useTypedSelector(state => state);
  const {
    mapSize,
    mapImageBounds,
    name,
    previewUrl,
  } = adminFloorMap;

  useEffect(() => {
    dispatch(getAttributes({ locationId }));
  }, [locationId]);

  useEffect(() => {
    dispatch(getFloorSchema({ floorId, showOwners: true, includeRooms: true }));
  }, []);

  useEffect(() => {
    if (adminFloorMapApiRequests.error) {
      open({
        text: adminFloorMapApiRequests.error,
        type: 'error',
        onClose: () => {
          dispatch(setFloorMapApiRequestsData({ error: '' }));
        },
      });
    }
  }, [adminFloorMapApiRequests.error]);

  return (
    <>
      <AddFloorHeader>
        <UploadFloorMap />
        <AddFloorDataUpdate />
        <AddFloorActionMenu />
      </AddFloorHeader>

      <Box className={styles.floorMap} dataTestId="floor-map-details">
        <Box>
          <FloorMapControls />

          <Box marginTop={14}>
            {(previewUrl) && (
              <Map
                mapImageBounds={mapImageBounds}
                mapSize={mapSize}
                name={name}
                previewUrl={previewUrl}
              />
            )}
          </Box>
        </Box>

        <DrawingDetails />
      </Box>
    </>
  );
}
