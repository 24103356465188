import AppRoutes from 'App/AppRoutes';
import clsx from 'clsx';
import CustomI18nProvider from 'i18n';
import mySaga from 'Store/Sagas';
import qs from 'qs';
import SnackbarProvider from 'Components/Snackbar/SnackbarProvider';
import store, { sagaMiddleware, useTypedSelector } from 'Store/Redux/store';
import { BrowserRouter, useHistory, useLocation } from 'react-router-dom';
import { getMsalConfig } from 'Store/Services/AuthConfig';
import { getThemes } from 'Theme/getThemes';
import { GlobalStyles } from 'Theme/GlobalStyles';
import { MsalProvider as MsalProviderReact } from '@azure/msal-react';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider, useDispatch } from 'react-redux';
import { PublicClientApplication } from '@azure/msal-browser';
import { selectThemeFromEmail, setTheme } from 'Store/Redux/Ducks/configDuck';
import { ThemeProvider as ThemeProviderStyledComponents } from 'styled-components';
import { StylesProvider as StylesProviderV4, ThemeProvider as ThemeProviderV4, createGenerateClassName } from '@material-ui/core';
import { ThemeProvider as ThemeProviderV5 } from '@mui/material';
import { Themes } from 'Store/Redux/Ducks/configDuck/models';
import { useEffect, useLayoutEffect, useState } from 'react';
import { theme as themeV5 } from "theme";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import 'Components/Styles/index.scss';
import 'Styles/globals.scss';
import { PushSetup } from 'components';
import { AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { reactPlugin } from 'config/applicationInsights';
import { t } from '@lingui/macro';

sagaMiddleware.run(mySaga);

const persistor = persistStore(store);

/**
 * Faz o scroll até o topo da página sempre que trocar de tela.
 * Não pode ser colocado inline junto com o <App /> porque o useLocation()
 * para de funcionar.
 */
function Wrapper({ children }: any) {
  const location = useLocation();
  const [loaded, setLoaded] = useState(false);

  // Scroll to top if path changes
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  useLayoutEffect(() => {
    setLoaded(true);
  }, []);

  const classes = clsx('app-wrapper', {
    preload: !loaded,
  });

  return <div className={classes}>{children}</div>;
}

const generateClassName = createGenerateClassName({
  disableGlobal: true,
  seed: "mui4",
});

/**
 * Configures Material and Styled Components Theme Providers.
 * Sets the correct theme based on current URL.
 */
function ThemesWrapper({ children }: React.PropsWithChildren<{}>) {
  const dispatch = useDispatch();
  const history = useHistory();
  const { config, login, profile } = useTypedSelector(state => state);
  const { theme, themeName } = config;

  const themes = getThemes(themeName);

  useEffect(() => {
    // @ts-ignore Typescript is not assigning string correctly to Theme
    const localStorageTheme: Themes = localStorage.getItem('theme') ?? 'ipg';

    // If user is not logged in, set theme to one in localStorage
    if (!login.token && themeName !== localStorageTheme) {
      dispatch(setTheme(localStorageTheme));
    } else if (profile.email) {
      // if user is logged in, save theme in localStorage and redux
      const newTheme = selectThemeFromEmail(profile.email);
      localStorage.setItem('theme', newTheme);

      if (newTheme !== themeName) {
        dispatch(setTheme(newTheme));
      }
    }
  }, [login.token, profile.email]);

  useEffect(() => {
    const { theme } = qs.parse(history.location.search.replace('?', ''));
    let newTheme: Themes = 'ipg';

    if (theme) {
      if (theme === 'ogBlue') {
        newTheme = 'ogBlue';
      } else if (theme === 'ipg') {
        newTheme = 'ipg';
      } else if (theme === 'green') {
        newTheme = 'green';
      }

      dispatch(setTheme(newTheme));
      localStorage.setItem('theme', newTheme);
    }
  }, [history.location.search]);

  return (
    <ThemeProviderStyledComponents theme={theme}>
      <GlobalStyles />

      <StylesProviderV4 generateClassName={generateClassName}>
        <ThemeProviderV4 theme={themes.material}>
          <ThemeProviderV5 theme={themeV5}>
            {children}
          </ThemeProviderV5>
        </ThemeProviderV4>
      </StylesProviderV4>
    </ThemeProviderStyledComponents>
  );
}

function MsalProvider({ children }: React.PropsWithChildren<{}>) {
  const { companyAccount: userAccount } = useTypedSelector(state => state.config);

  const msalConfig = getMsalConfig(userAccount);

  const msalInstance = new PublicClientApplication(msalConfig);

  return (
    <div key={userAccount}>
      <MsalProviderReact instance={msalInstance}>
        {children}
      </MsalProviderReact>
    </div>
  );
}

/**
 * Entrypoint component
 *
 * (ReduxSaga) - Configured globaly with sagaMiddleware.run(mySage) above
 *
 * <Provider /> - Redux
 * <PersistGate /> - Redux Persist
 * <BrowserRouter /> - React Router
 * <ThemesWrapper /> - Load Material and Styled Components Provider
 * <CustomI18nProvider /> - Load LinguiJs and activate internationalization (i18n)
 * <Wrapper /> - Wrapper used to scroll to top when navigating between pages
 * <SnackbarProvider /> - Global Snackbar functionality, using Material snackbar
 * <AppRoutes /> - File with Routes configuration
 */
export default function App() {
  return (
    <AppInsightsErrorBoundary appInsights={reactPlugin} onError={() => <p>{t`Something went wrong, please contact support.`}</p>}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ThemesWrapper>
              <CustomI18nProvider>
                <Wrapper>
                  <SnackbarProvider>
                    <MsalProvider>
                      <AppRoutes />
                      <PushSetup />
                    </MsalProvider>
                  </SnackbarProvider>
                </Wrapper>
              </CustomI18nProvider>
            </ThemesWrapper>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </AppInsightsErrorBoundary>
  );
}
