import Desk from './Desk';
import Error404 from '../Error404';
import Floor from './Floor';
import Layout from './Layout';
import Location from './Location';
import { AnimatePresence } from 'framer-motion';
import { DeskGuard, FloorGuard } from './guards';
import { ProtectedRoute } from 'Components/ProtectedRoute';
import { Redirect, Route, Switch } from 'react-router-dom';
import { t } from '@lingui/macro';

export default function CreateNewBookingRoutes() {
  return (
    <Layout title={t`Create new booking`}>
      <AnimatePresence exitBeforeEnter initial={true}>
        <Switch>
          <Route
            exact
            path='/create-new-booking/select-location'
            render={() => <Location />}
          />

          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-booking/select-location" />}
            guards={[FloorGuard]}
            path='/create-new-booking/select-floor'
            render={() => <Floor />}
          />

          <ProtectedRoute
            exact
            fallback={() => <Redirect to="/create-new-booking/select-floor" />}
            guards={[DeskGuard]}
            path='/create-new-booking/select-desk'
            render={() => <Desk />}
          />

          <Route path='*' render={() => <Error404 />} />
        </Switch>
      </AnimatePresence >
    </Layout >
  );
}
