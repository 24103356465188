export enum ApiTag {
  BOOKING = "Booking",
  FLOOR = "Floor",
  GROUP = "Group",
  LOCATION = "Location",
  RESERVATION = "Reservation",
  ROOM_SCHEDULE = "Room.Schedule",
  USER_SAVED_DESK = "User.Saved.Desk",
  USER_HOME_COUNTS = "User.Counts.Home",
}
