import React, { useEffect, useMemo, useRef, useState } from "react";
import { CalendarViewSchedule, CalendarViewSelection, WeeklyCalendarViewProps } from "./types";
import { Box, CircularProgress, Divider, Fade, IconButton, Skeleton, Typography, useTheme } from "@mui/material";
import { add, addDays, addMinutes, addWeeks, differenceInMinutes, eachDayOfInterval, eachMinuteOfInterval, endOfDay, isAfter, isBefore, isEqual, isSameDay, set, startOfDay } from "date-fns";
import { isCalendarViewScheduleVisible, isCalendarViewStepDisabled, resolveCalendarViewScheduleInterval, resolveCalendarViewScheduleSpan, resolveCalendarViewStepDates } from "./utils";
import { ArrowBackIosRounded, ArrowForwardIosRounded } from "@mui/icons-material";
import { t } from "@lingui/macro";
import { WeeklyCalendarViewStep } from "./weekly-calendar-view-step";
import { WeeklyCalendarViewSchedule } from "./weekly-calendar-view-schedule";
import { isDaySelectorDisabled } from "../day-selector";
import { format } from "date-fns-tz";

// * Right now this expects rooms only, but changes can be made to support other types of reservation resources (desk, etc)

export const WeeklyCalendarView: React.FC<WeeklyCalendarViewProps> = (props) => {
  const { selectedDay, height, step = 15, isLoading, item, stepHeight = 26, defaultSelection, maxDate, onChange, onStartDayChange } = props;
  const theme = useTheme();
  const now = new Date();
  const [isOnHighLoad, setIsOnHighLoad] = useState(false);
  const [selection, setSelection] = useState<CalendarViewSelection>();
  const [startDay, setStartDay] = useState(selectedDay);
  const days = useMemo(
    () => eachDayOfInterval({ start: startDay, end: add(startDay, { weeks: 1 }) }).slice(0, -1),
    [startDay],
  );
  const initialScrollTop = useMemo(() => {
    const now = new Date();
    const minutes = eachMinuteOfInterval(
      { start: startOfDay(now), end: set(now, { hours: 8, minutes: 0, seconds: 0, milliseconds: 0 }) },
      { step },
    );
    
    return minutes.slice(0, -1).length * stepHeight;
  }, []);
  const daysStepDates = useMemo(() => days.map((day) => resolveCalendarViewStepDates(day, step)), [days, step]);
  const seventhDay = addDays(startDay, 7);
  const viewRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (viewRef.current) {
      viewRef.current.scrollTop = initialScrollTop;
    }
  }, [viewRef, item?.id, startDay.getTime()]);

  useEffect(() => {
    const timeout = isLoading ? setTimeout(() => setIsOnHighLoad(true), 5000) : undefined;

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }

      setIsOnHighLoad(false);
    };
  }, [isLoading, setIsOnHighLoad]);

  useEffect(() => {
    if (!isEqual(selectedDay, startDay)) {
      setStartDay(selectedDay);
    }
  }, [selectedDay.getTime()]);

  useEffect(() => {
    onStartDayChange?.([days[0], endOfDay(days[days.length - 1])]);
  }, [startDay]);

  useEffect(() => {
    if (selection && selection.id !== item?.id) {
      setSelection(undefined);
    }
  }, [item]);

  useEffect(() => {
    if (selection && selection.start) {
      const dayIndex = days.findIndex((day) => selection.start && isSameDay(selection.start, day));
      const stepDates = daysStepDates[dayIndex];
      const span = resolveCalendarViewScheduleSpan(selection.start, stepDates[0], step, stepDates[0]) * stepHeight;
      const top = span ? span - stepHeight : 0;

      if (viewRef.current) {
        viewRef.current.scrollTop = top;
      }
    }
  }, [selection, viewRef]);

  useEffect(() => {
    if (defaultSelection) {
      const { id, start, end, summary } = defaultSelection;
      const shouldSetSelection = start
        && end
        && (isSameDay(start, days[0]) || isAfter(start, days[0]) 
        && (isSameDay(end, days[days.length -1]) || isBefore(end, days[days.length -1])))
        && defaultSelection.id === item?.id;

      if (shouldSetSelection) {
        setSelection({ id, start, end, summary });
      }
    } else if (selection) {
      setSelection(undefined);
    }
  }, [selectedDay.getTime(), item?.id, JSON.stringify(defaultSelection)]);

  const handleDaySelectorClick = (direction: "next" | "previous") => {
    setSelection(undefined);
    setStartDay(direction === "next" ? addWeeks(startDay, 1) : addWeeks(startDay, -1));
  };

  const handleStepClick = (stepDate: Date) => {
    const isNewSelection = (selection?.start && !isSameDay(stepDate, selection.start))
      || !selection
      || (selection.start && selection.end && differenceInMinutes(selection.end, selection.start) > step)
      || (selection.start && (isEqual(stepDate, selection.start) || isBefore(stepDate, selection.start)));

    if (isNewSelection) {
      setSelection({ id: item?.id || "", start: stepDate });
      onChange?.({ id: item?.id || "", start: stepDate, end: addMinutes(stepDate, step) });
    } else if (selection) {
      const { id, start } = selection;
      const newSelection = { id, start, end: addMinutes(stepDate, step) };

      setSelection(newSelection);
      onChange?.(newSelection);
    }
  };

  const handleSelectionClick = () => {
    if (selection?.start) {
      setSelection(undefined);
      onChange?.({ id: selection.id, start: undefined, end: undefined });
    }
  };

  const labels: JSX.Element[] = [];

  for (let index = 0; index < 24; index++) {
    const hour = `${index}`.padStart(2, "0");
    const label = `${hour}:00`;

    labels.push((
      <Box bgcolor="white" height={stepHeight * (60 / step)} key={label} width="100%">
        {<Typography width="100%">{label}</Typography>}
      </Box>
    ));
  }

  const daysSchedules = days.map(() => []) as CalendarViewSchedule[][];

  for (const schedule of item?.schedules || []) {
    for (const [index, day] of days.entries()) {
      if (isSameDay(schedule.startDate, day) || isSameDay(schedule.endDate, day)) {
        daysSchedules[index].push(schedule);
      }
    }
  }

  return (
    <Box display="flex" flexDirection="column" height={height} width="100%">
      <Box alignItems="center" display="flex" justifyContent="space-between" marginBottom={1}>
        <Typography fontSize={14} fontWeight="600">
          {format(days[0], "d")}-{format(days[days.length - 1], "d LLL, yyyy")}
        </Typography>
        <Box paddingBottom={1} paddingTop={2}>
          <IconButton
            color="primary"
            disabled={isDaySelectorDisabled("previous", startDay, now) || isLoading}
            onClick={() => handleDaySelectorClick("previous")}
            size="small"
          >
            <ArrowBackIosRounded fontSize="small" />
          </IconButton>
          <IconButton
            color="primary"
            disabled={isDaySelectorDisabled("next", seventhDay, now, maxDate) || isLoading}
            onClick={() => handleDaySelectorClick("next")}
            size="small"
          >
            <ArrowForwardIosRounded fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box display="flex" marginBottom={1} paddingRight={1}>
        <Box flex="1 0 12.5%" width="12.5%">
          <Typography color={theme.palette.grey[400]} fontSize={13} textAlign="center" width="100%">{t`Time`}</Typography>
        </Box>
        {days.map((day) => {
          const isSelectedDay = isSameDay(selectedDay, day);
          const isSelectionDate = selection?.start ? isSameDay(selection.start, day) : false;
          const isBlocked = maxDate && isAfter(day, maxDate);

          return (
            <Box display="flex" flex="1 0 12.5%" flexDirection="column" key={day.toISOString()} width="12.5%">
              <Typography
                color={isBlocked ? theme.palette.grey[200] : theme.palette.grey[400]}
                fontSize={13}
                marginBottom={0.5} 
                textAlign="center"
                textTransform="uppercase"
                width="100%"
              >
                {format(day, "E")}
              </Typography>
              <Typography
                bgcolor={isSelectionDate ? theme.background.blue : undefined}
                borderRadius={isSelectionDate ? 2 : 0}
                color={isSelectedDay || isSelectionDate ? "primary" : isBlocked ? theme.palette.grey[300] : undefined}
                fontWeight="600"
                paddingBottom={0.5}
                paddingTop={0.5}
                textAlign="center"
                width="100%"
              >
                {format(day, "d")}
              </Typography>
            </Box>
          );
        })}
      </Box>
      <Divider sx={{ borderColor: theme.palette.grey[100] }} />
      <Box display="flex" flexDirection="column" overflow="hidden" position="relative">
        {isLoading ? (
          <>
            <Skeleton
              animation="wave"
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                bottom: 0,
                left: "12.5%",
                height: "auto",
                zIndex: 100,
                bgcolor: "rgba(245, 245, 245, 0.4)",
                borderRadius: 0,
              }}
              variant="rectangular"
            />
            <Fade in={isOnHighLoad}>
              <Box
                alignItems="center"
                bottom={0}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                left="12.5%"
                paddingX={8}
                position="absolute"
                right={0}
                top={0}
                zIndex={200}
              >
                <CircularProgress size={32} sx={{ mb: 2 }} />
                <Typography color={theme.palette.grey[700]} fontSize={14} textAlign="center">
                  {t`Please, wait while we fetch room schedule from Outlook`}
                </Typography>
              </Box>
            </Fade>
          </>
        ) : undefined}
        <Box display="flex" ref={viewRef} sx={{ overflowY: "auto", overflowX: "unset" }}>
          <Box flex="1 0 12.5%" width="12.5%">
            {labels}
          </Box>
          {days.map((day, index) => {
            const stepDates = daysStepDates[index] || [];
            const firstStepDate = stepDates[0];
            const lastStepDate = stepDates[stepDates.length - 1];
            const isBlocked = maxDate && isAfter(day, maxDate);

            return (
              <Box flex="1 0 12.5%" key={day.toISOString()} position="relative" width="12.5%">
                {stepDates.map((stepDate) => (
                    <WeeklyCalendarViewStep
                      bgcolor={isBlocked ? theme.palette.grey[100] : undefined}
                      disabled={isCalendarViewStepDisabled(stepDate, item?.id || "", daysSchedules[index], selection) || isBlocked}
                      height={stepHeight}
                      key={`${day.getTime()}${stepDate.getTime()}`}
                      onClick={() => handleStepClick(stepDate)}
                      tooltip={t`Click to select time`}
                    />
                  ))}
                {daysSchedules[index]?.map(({ startDate, endDate, summary }) => isCalendarViewScheduleVisible(endDate, step, firstStepDate) ? (
                  <WeeklyCalendarViewSchedule
                    endDate={endDate}
                    firstStepDate={firstStepDate}
                    key={`${day.getTime()}${startDate.getTime()}`}
                    lastStepDate={lastStepDate}
                    startDate={startDate}
                    step={step}
                    stepHeight={stepHeight}
                    summary={summary}
                  />
                ): undefined)}
                {selection && selection.start && (isSameDay(selection.start, day) || (selection.end && isSameDay(selection.end, day))) ? (
                  <Box
                    bgcolor={selection.start && selection.end ? theme.background.blue : theme.palette.grey[100]}
                    borderRadius={2}
                    height={
                      selection.end 
                        ? (resolveCalendarViewScheduleInterval(selection.start, selection.end, step, firstStepDate, lastStepDate) * stepHeight) 
                        : stepHeight
                    }
                    left={0}
                    onClick={handleSelectionClick}
                    position="absolute"
                    top={resolveCalendarViewScheduleSpan(selection.start, firstStepDate, step, firstStepDate) * stepHeight}
                    width="100%"
                    zIndex={1}
                  />
                ) : undefined }
              </Box>
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
