import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Popup from "reactjs-popup";
import styles from "./styles.module.scss";

import ButtonBase from "@material-ui/core/ButtonBase";
import { IAdminRoom, IAdminRoomAmenity } from "../../../../../Store/roomsManagement/models";
import Text from "../../../../../../Components/Text";
import Box from "../../../../../../Components/Box";
import Checkbox from "../../../../../../Components/Checkbox";
import { editAdminFloorRoom } from "../../../../../Store/roomsManagement";
import { useTypedSelector } from "Store/Redux/store";

const attributesIcons: { name: string; image: string }[] = [];

interface DeskAmenitiesPopupProps {
  room: IAdminRoom;
}

export default function RoomAmenities(props: DeskAmenitiesPopupProps) {
  const dispatch = useDispatch();
  const { locationId, floorId } = useParams<{ locationId: string ; floorId: string;}>();
  const { attributesRoom } = useTypedSelector(state => state.adminAttributes);
  const { room } = props;

  const onChangeAmenities = (value: boolean, amenity: IAdminRoomAmenity) => {
    const amenityIds: string[] = [];

    if (room?.amenities) {
      for (const roomAmenity of room.amenities) {
        if (!value && roomAmenity.id === amenity.id) {
          continue;
        }

        amenityIds.push(roomAmenity.id);
      }
    }

    if (value && !amenityIds.includes(amenity.id)) {
      amenityIds.push(amenity.id);
    }

    dispatch(editAdminFloorRoom({
      locationId,
      floorId,
      roomId: room.id,
      data: { amenityIds },
    }));
  };

  return (
    <Popup
      closeOnDocumentClick
      contentStyle={{ marginLeft: 27, width: 270 }}
      position="bottom left"
      trigger={(
        <ButtonBase
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-start",
            padding: "10px 0",
          }}
        >
          <Text size="md" truncate>
            {room?.amenities?.length === 0
              ? '-'
              : room?.amenities?.map(({ name }, index) => {
                return `${name}${index + 1 < room?.amenities?.length ? ', ' : ''}`;
              })}
          </Text>
        </ButtonBase>
      )}
    >
      <Box borderStyle="shadow" className={"popover-content"}>
        {attributesRoom.map((amenity, index) => {
          const imagePath = attributesIcons.find(icon => icon.name === amenity.name);
          const checked = room?.amenities?.some(({ name }) => name === amenity.name);

          return (
            <Checkbox
              checked={checked}
              classes={{ container: styles.checkbox }}
              key={amenity.name + index}
              label={
                <Box alignItems="center" className={`${styles.attributeLabel}`} display="flex">
                  {imagePath && <img alt={imagePath.name} className={styles.attributeImage} src={imagePath.image} />}
                  {amenity.name}
                </Box>
              }
              name={amenity.name}
              onChange={(value) => onChangeAmenities(value, amenity)}
            />
          );
        })}
      </Box>
    </Popup>
  );
}