export const ApiUrls = {
  developmentClientAzure: 'https://bknd-together-stage.azurewebsites.net',
  productionClientAzure: 'https://bknd-together-prod.azurewebsites.net',
  development: 'https://bknd-together-dev.azurewebsites.net',
};

/**
 * Return the correct API Url depending on the REACT_APP_STAGE defined in package.json scripts
 */
export function getApiUrl(): string {
  const stage = process.env.REACT_APP_STAGE ?? 'development';

  let apiUrl = ApiUrls.development;

  if (stage === 'developmentClientAzure') {
    apiUrl = ApiUrls.developmentClientAzure;
  } else if (stage === 'productionClientAzure') {
    apiUrl = ApiUrls.productionClientAzure;
  }

  return apiUrl;
}
