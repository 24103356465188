import { FloorMapObject } from "../../../../../Store/floorMapDuck/models";
import Box from "../../../../../../Components/Box";
import styles from "./styles.module.scss";
import Text from "../../../../../../Components/Text";
import { Trans } from "@lingui/macro";
import Divider from "../../../../../../Components/Divider";
import MeetingDetailsEmail from "./MeetingDetailsEmail";
import MeetingDetailsName from "./MeetingDetailsName";
import MeetingDetailsStatus from "./MeetingDetailsStatus";
import MeetingDetailsCapacity from "./MeetingDetailsCapacity";
import MeetingDetailsAmenities from "./MeetingDetailsAmenities";
import MeetingDetailsAdditionServices from "./MeetingDetailsAdditionServices";
import MeetingDetailsGroups from "./MeetingDetailsGroups";
import React from "react";
import { RoomImages } from "./room-images";
import { useParams } from "react-router-dom";
import { ToastProvider } from "components";
import MeetingDetailsDescription from "./MeetingDetailsDescription";
import MeetingDetailsReservationDayLimit from "./MeetingDetailsReservationDayLimit";

interface Props {
  meeting: FloorMapObject;
}
 
export default function MeetingDetails(props: Props) {
  const { meeting } = props;
  const { locationId, floorId } = useParams<{ locationId: string; floorId: string }>();

  return (
    <Box className={styles.deskDetails}>
      <Text size="xlg" weight="semi-bold">
        <Trans>Room details</Trans>
      </Text>
      <Box marginTop={16}>
        <Box marginBottom={16}>
          <MeetingDetailsEmail meeting={meeting} />
          <MeetingDetailsName meeting={meeting} />
          <MeetingDetailsStatus meeting={meeting} />
          <MeetingDetailsGroups meeting={meeting} />
          <MeetingDetailsCapacity meeting={meeting} />
          <MeetingDetailsReservationDayLimit meeting={meeting} />
          <MeetingDetailsDescription meeting={meeting} />
        </Box>
        <Divider />
        <Box marginBottom={8} marginTop={16}>
          <MeetingDetailsAmenities meeting={meeting} />
        </Box>
        <Divider />
        <Box marginBottom={16} marginTop={16}>
          <MeetingDetailsAdditionServices meeting={meeting} />
        </Box>
        <Divider />
        <ToastProvider>
          <Box marginTop={16}>
            <RoomImages floorId={floorId} locationId={locationId} roomId={meeting.roomId || ""} />
          </Box>
        </ToastProvider>
      </Box>
    </Box>
  );
}
