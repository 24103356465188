import { parseArrayQueryParam } from "../../utils";
import { ApiTag } from "../enums";
import { togetherApi } from "../together-api";
import { APIResponseBody, CreateRoomImageInput, DeleteRoomImageInput, GetFloorAvailableRoomsInput, GetFloorRoomsSchedulesInput, GetLocationAvailableRoomsInput, GetLocationRoomsSchedulesInput, GetRoomByIdInput, GetRoomImagesInput, GetRoomsScheduleInput, Image, PaginatedAPIResponseBody, Room, RoomSchedule, UpdateRoomImagesInput, VariablePaginatedAPIResponseBody } from "../types";
import { GetFloorRoomsInput } from "../types/inputs/get-floor-rooms-input.type";

const rooms = togetherApi.injectEndpoints({
  endpoints: (builder) => ({
    getLocationAvailableRooms: builder.query<VariablePaginatedAPIResponseBody<Room>, GetLocationAvailableRoomsInput>({
      providesTags: [ApiTag.ROOM_SCHEDULE],
      query: ({
        locationId,
        availabilityStartDate,
        availabilityEndDate,
        availabilityStartTime,
        availabilityEndTime,
        reservationsToExclude,
        roomsToExclude,
        cursor,
        limit = 15,
      }) => ({
        url: `/api/locations/${locationId}/available-rooms`,
        params: {
          "custom[availabilityStartDate]": availabilityStartDate,
          "custom[availabilityEndDate]": availabilityEndDate,
          "custom[availabilityStartTime]": availabilityStartTime,
          "custom[availabilityEndTime]": availabilityEndTime,
          ...parseArrayQueryParam("custom[reservationsToExclude]", reservationsToExclude),
          ...parseArrayQueryParam("custom[roomsToExclude]", roomsToExclude),
          cursor: cursor,
          limit: limit,
        },
      }),
    }),
    getLocationRoomsSchedules: builder.query<PaginatedAPIResponseBody<RoomSchedule>, GetLocationRoomsSchedulesInput>({
      providesTags: [ApiTag.ROOM_SCHEDULE],
      query: ({
        locationId,
        startDate,
        endDate,
        limit,
        page,
      }) => ({
        url: `/api/locations/${locationId}/rooms/schedules`,
        params: {
          limit,
          page,
          "custom[startDate]": startDate.toISOString(),
          "custom[endDate]": endDate.toISOString(),
        },
      }),
    }),
    getRoomSchedule: builder.query<APIResponseBody<RoomSchedule>, GetRoomsScheduleInput>({
      providesTags: [ApiTag.ROOM_SCHEDULE],
      query: ({
        locationId,
        floorId,
        roomId,
        startDate,
        endDate,
      }) => ({
        url: `/api/locations/${locationId}/floors/${floorId}/rooms/${roomId}/schedule`,
        params: {
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
        },
      }),
    }),
    getRoomById: builder.query<APIResponseBody<Room>, GetRoomByIdInput>({
      query: ({ locationId, floorId, roomId, include }) => ({
        url: `/api/locations/${locationId}/floors/${floorId}/rooms/${roomId}`,
        params: {
          ...parseArrayQueryParam("include", include),
        },
      }),
    }),
    getFloorAvailableRooms: builder.query<VariablePaginatedAPIResponseBody<Room>, GetFloorAvailableRoomsInput>({
      providesTags: [ApiTag.ROOM_SCHEDULE],
      query: ({
        locationId,
        floorId,
        availabilityStartDate,
        availabilityEndDate,
        availabilityStartTime,
        availabilityEndTime,
        reservationsToExclude,
        roomsToExclude,
        cursor,
        limit = 15,
      }) => ({
        url: `/api/locations/${locationId}/floors/${floorId}/available-rooms`,
        params: {
          "custom[availabilityStartDate]": availabilityStartDate,
          "custom[availabilityEndDate]": availabilityEndDate,
          "custom[availabilityStartTime]": availabilityStartTime,
          "custom[availabilityEndTime]": availabilityEndTime,
          ...parseArrayQueryParam("custom[reservationsToExclude]", reservationsToExclude),
          ...parseArrayQueryParam("custom[roomsToExclude]", roomsToExclude),
          cursor: cursor,
          limit: limit,
        },
      }),
    }),
    getFloorRoomsSchedules: builder.query<PaginatedAPIResponseBody<RoomSchedule>, GetFloorRoomsSchedulesInput>({
      providesTags: [ApiTag.ROOM_SCHEDULE],
      query: ({
        locationId,
        floorId,
        startDate,
        endDate,
        limit,
        page,
      }) => ({
        url: `/api/locations/${locationId}/floors/${floorId}/rooms/schedules`,
        params: {
          limit,
          page,
          "custom[startDate]": startDate.toISOString(),
          "custom[endDate]": endDate.toISOString(),
        },
      }),
    }),
    getRoomImages: builder.query<PaginatedAPIResponseBody<Image>, GetRoomImagesInput>({
      query: ({ locationId, floorId, roomId, limit }) => ({
        url: `/api/locations/${locationId}/floors/${floorId}/rooms/${roomId}/images`,
        params: {
          limit,
        },
      }),
    }),
    deleteRoomImage: builder.mutation<APIResponseBody<undefined>, DeleteRoomImageInput>({
      query: ({ locationId, floorId, roomId, roomImageId }) => ({
        url: `/api/locations/${locationId}/floors/${floorId}/rooms/${roomId}/images/${roomImageId}`,
        method: "DELETE",
      }),
    }),
    createRoomImage: builder.mutation<APIResponseBody<Image>, CreateRoomImageInput>({
      query: ({ locationId, floorId, roomId, file }) => {
        const body = new FormData();

        body.append("file", file);

        return {
          url: `/api/locations/${locationId}/floors/${floorId}/rooms/${roomId}/images`,
          method: "POST",
          body,
        };
      },
    }),
    updateRoomImages: builder.mutation<APIResponseBody<{ items: Image[] }>, UpdateRoomImagesInput>({
      query: ({ locationId, floorId, roomId, images: entities }) => ({
        url: `/api/locations/${locationId}/floors/${floorId}/rooms/${roomId}/images`,
        method: "PATCH",
        body: { entities },
      }),
    }),
    getFloorRooms: builder.query<PaginatedAPIResponseBody<Room>, GetFloorRoomsInput>({
      query: ({
        locationId,
        floorId,
        page,
        roomsToExclude,
        limit = 15,
      }) => ({
        url: `/api/v3/locations/${locationId}/floors/${floorId}/rooms`,
        params: {
          limit,
          page,
          ...parseArrayQueryParam("custom[roomsToExclude]", roomsToExclude),
        },
      }),
    }),
  }),
});

export const {
  useGetLocationAvailableRoomsQuery,
  useGetLocationRoomsSchedulesQuery,
  useGetRoomScheduleQuery,
  useLazyGetRoomScheduleQuery,
  useGetRoomByIdQuery,
  useLazyGetRoomByIdQuery,
  useGetFloorAvailableRoomsQuery,
  useLazyGetFloorAvailableRoomsQuery,
  useGetFloorRoomsSchedulesQuery,
  useLazyGetFloorRoomsSchedulesQuery,
  useGetRoomImagesQuery,
  useLazyGetRoomImagesQuery,
  useDeleteRoomImageMutation,
  useCreateRoomImageMutation,
  useUpdateRoomImagesMutation,
  useGetFloorRoomsQuery,
  useLazyGetFloorRoomsQuery,
} = rooms;
