import { useTypedSelector } from "../../../../Store/Redux/store";
import Box from "../../../../Components/Box";
import styles from "../styles.module.scss";
// @ts-ignore
import DoubleScrollbar from 'react-double-scrollbar';
import { t } from "@lingui/macro";

import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import { Skeleton } from "@material-ui/lab";
import Text from "../../../../Components/Text";
import RoomsTableHeader from "./RoomsTableHeader";
import RoomsTableBody from "./RoomsTableBody";
import { IAdminRoom } from "../../../Store/roomsManagement/models";

interface Props {
  rooms: IAdminRoom[];
}

export default function RoomsTable(props: Props) {
  const { roomsManagement } = useTypedSelector(state => state);
  const { rooms } = props;
  const { loading } = roomsManagement;  

  return (
    <Box className={styles.rooms}>
      <TableContainer>
        <DoubleScrollbar>
          <Table
            aria-label={t`Rooms management user list`}
            aria-labelledby={t`rooms list`}
            className={styles.roomsTable}
          >
            <RoomsTableHeader />

            {!loading && (
              <TableBody classes={{ root: styles.tableBody }}>
                {
                  rooms.map((room, index) => {
                    return (<RoomsTableBody index={index} key={index} room={room} />);
                  })
                }
              </TableBody>
            )}
          </Table>
        </DoubleScrollbar>
      </TableContainer>
      {loading ? (
        <Box height={200}>
          <Skeleton height="100%" variant="rect" />
        </Box>
      ) : (
        !rooms.length && (
          <Box marginTop={100}>
            <Text align="center" color="lightGray" size="md">
              No rooms found
            </Text>
          </Box>
        )
      )}
    </Box>
  );
}