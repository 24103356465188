import {useDispatch} from "react-redux";
import {useTypedSelector} from "../../../../Store/Redux/store";
import {setFloorMapData} from "../../../Store/floorMapDuck";
import {t} from "@lingui/macro";
import {FloorMapObject} from "../../../Store/floorMapDuck/models";
import styles from "../styles.module.scss";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "../../../../Components/Checkbox";

export default function DeskListTableHeader() {
  const dispatch = useDispatch();
  const { adminFloorMap } = useTypedSelector(state => state);
  const { desks, editDeskList } = adminFloorMap;
  const desksArray = Object.keys(desks).map(deskId => desks[deskId]);

  const headCells = [
    { id: 'Checkbox', label: '' },
    { id: 'DeskName', label: t`Desk name` },
    { id: 'Section', label: t`Section` },
    { id: 'Status', label: t`Status` },
    { id: 'Owner', label: t`Owner` },
    { id: 'Groups', label: t`Groups` },
    { id: 'Amenities', label: t`Amenities` },
    { id: 'options', label: '' },
  ];

  // Toggle select all checkbox, using the correct logic to select all desks
  const handleSelectAll = (selectAll: boolean) => {
    const newSelectedDesks: {
      [deskId: string]: FloorMapObject;
    } = {};

    desksArray.forEach(desk => {
      if (selectAll) {
        newSelectedDesks[desk.id] = desk;
      } else {
        delete newSelectedDesks[desk.id];
      }
    });

    dispatch(setFloorMapData({
      editDeskList: {
        ...editDeskList,
        selectedDesks: newSelectedDesks,
      },
    }));
  };

  const isAllSelected = Object.keys(editDeskList.selectedDesks).length === desksArray.length;

  return (
    <TableHead classes={{ root: styles.tableHead }}>
      <TableRow classes={{ root: styles.tableRow }}>
        {headCells.map((headCell) => {
          if (headCell.id === 'Checkbox') {
            return (
              <TableCell classes={{ root: styles.tableCell }} key={headCell.id} padding="none">
                <Checkbox
                  checked={isAllSelected}
                  classes={{ container: styles.checkbox }}
                  label=""
                  name={t`selectAll`}
                  onChange={handleSelectAll}
                />
              </TableCell>
            );
          }

          return (
            <TableCell classes={{ root: styles.tableCell }} key={headCell.id} padding="none">
              {headCell.label}
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
}
