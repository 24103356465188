import Box from 'Components/Box';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import styles from './styles.module.scss';
import Switch from '@material-ui/core/Switch';
import Text from 'Components/Text';
import { editCovidSchedule, setCovidQuestionsData } from 'Admin/Store/covidQuestions';
import { Trans, t } from '@lingui/macro';
import TextField from '@material-ui/core/TextField';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTypedSelector } from 'Store/Redux/store';

export default function CovidScheduleTimes() {
  const dispatch = useDispatch();
  const { locationId } = useParams<{ locationId: string }>();
  const { adminCovidQuestions } = useTypedSelector(state => state);
  const { covidSchedule } = adminCovidQuestions;

  useEffect(() => {
    setScheduleStartTime(covidSchedule?.startTime);
    setScheduleReminderTime(covidSchedule?.reminderTime);
    setScheduleCancellationTime(covidSchedule?.cancellationTime);
    setCollectQuestionnaireAnswers(covidSchedule?.collectQuestionnaireAnswers);
  }, [covidSchedule]);

  const [scheduleStartTime, setScheduleStartTime] = useState<number | string>(covidSchedule?.startTime);
  const [scheduleReminderTime, setScheduleReminderTime] = useState<number | string>(covidSchedule?.reminderTime);
  const [scheduleCancellationTime, setScheduleCancellationTime] = useState<number | string>(covidSchedule?.cancellationTime);
  const [collectQuestionnaireAnswers, setCollectQuestionnaireAnswers] = useState(covidSchedule?.collectQuestionnaireAnswers);

  const onScheduleTimesChange = (event: any, param: string) => {
    // set 1 as min value
    const value = event.target.value.match(/^\d+$/) ?
      parseInt(event.target.value) : '';

    switch (param) {
      case 'startTime':
        setScheduleStartTime(value);
        break;
      case 'reminderTime':
        setScheduleReminderTime(value);
        break;
      case 'cancellationTime':
        setScheduleCancellationTime(value);
        break;
      default:
        console.log('');
    }

    if (value) {
      const updatedSchedule = {
        ...covidSchedule,
        [param]: value,
      };

      dispatch(editCovidSchedule({ locationId, schedule: updatedSchedule }));
    }
  };

  const onScheduleKeyboardCheck = (e: any) => {
    if (!e.key.match(/^\d+$/)) {
      e.preventDefault();
    }
  };

  const onScheduleFocusOut = (e: any) => {
    if (!e.target.value) {
      setTimeout(function () {
        dispatch(setCovidQuestionsData({ error: t`Schedule fields should not be empty` }));
      }, 500);
    }
  };

  const onUpdateCollectAnswers = () => {
    setCollectQuestionnaireAnswers(!collectQuestionnaireAnswers);

    dispatch(editCovidSchedule({
      locationId,
      schedule: {
        ...covidSchedule,
        collectQuestionnaireAnswers: !collectQuestionnaireAnswers,
      },
    }));
  };

  return (
    <Box className={styles.covidScheduleTimes} dataTestId="covid-schedule-times">
      <Box marginBottom={20}>
        <h4>
          <Trans>Define notification times</Trans>
        </h4>

        <div className={styles.covidScheduleTimesText}>
          <Trans>(measured from start of booking)</Trans>
        </div>
      </Box>

      <Box alignItems="center" className={styles.covidScheduleTimesBlock} display="flex" justifyContent="between">
        <div className={styles.covidScheduleTimesText}>
          <Trans>Questionnaire start time</Trans>
        </div>

        <Box alignItems="center" className={styles.covidScheduleTimesInputs} dataTestId="covid-schedule-times-startTime" display="flex">
          <TextField
            InputProps={{ inputProps: { min: 1, max: 24 } }}
            className={`input input--default ${styles.covidScheduleTimesInput}`}
            defaultValue={scheduleStartTime}
            disabled={!covidSchedule?.isActive}
            id="questionnaire-start"
            label=""
            onBlur={onScheduleFocusOut}
            onChange={(e) => onScheduleTimesChange(e, 'startTime')}
            onKeyPressCapture={onScheduleKeyboardCheck}
            type="number"
            value={scheduleStartTime}
          />

          <div className={styles.covidScheduleTimesText}>
            <Trans comment="Questionnaire start time value. Ex: 4 hrs">hrs</Trans>
          </div>
        </Box>
      </Box>
      <Box alignItems="center" className={styles.covidScheduleTimesBlock} display="flex" justifyContent="between">
        <div className={styles.covidScheduleTimesText}>
          <Trans>Questionnaire reminder time</Trans>
        </div>

        <Box alignItems="center" className={styles.covidScheduleTimesInputs} dataTestId="covid-schedule-times-reminderTime" display="flex">
          <TextField
            InputProps={{ inputProps: { min: 1, max: 1440 } }}
            className={`input input--default ${styles.covidScheduleTimesInput}`}
            defaultValue={scheduleReminderTime}
            disabled={!covidSchedule?.isActive}
            id="questionnaire-reminder"
            label=""
            onBlur={onScheduleFocusOut}
            onChange={(e) => onScheduleTimesChange(e, 'reminderTime')}
            onKeyPressCapture={onScheduleKeyboardCheck}
            type="number"
            value={scheduleReminderTime}
          />

          <div className={styles.covidScheduleTimesText}>
            <Trans comment="Questionnaire start time value. Ex: 30 mins">mins</Trans>
          </div>
        </Box>
      </Box>

      <Box alignItems="center" className={styles.covidScheduleTimesBlock} display="flex" justifyContent="between">
        <div className={styles.covidScheduleTimesText}>
          <Trans>Questionnaire based cancelletion</Trans>
        </div>

        <Box alignItems="center" className={styles.covidScheduleTimesInputs} dataTestId="covid-schedule-times-cancellationTime" display="flex">
          <TextField
            InputProps={{ inputProps: { min: 1, max: 1440 } }}
            className={`input input--default ${styles.covidScheduleTimesInput}`}
            defaultValue={scheduleCancellationTime}
            disabled={!covidSchedule?.isActive}
            id="questionnaire-cancellation"
            label=""
            onBlur={onScheduleFocusOut}
            onChange={(e) => onScheduleTimesChange(e, 'cancellationTime')}
            onKeyPressCapture={onScheduleKeyboardCheck}
            type="number"
            value={scheduleCancellationTime}
          />
          <div className={styles.covidScheduleTimesText}>
            <Trans comment="Questionnaire start time value. Ex: 30 mins">mins</Trans>
          </div>
        </Box>
      </Box>

      <Box alignItems='center' display='flex' gap={10} marginTop={35}>
        <label htmlFor="Collect answers">
          <Text weight='semi-bold'>
            <Trans>
              Collect answers
            </Trans>
          </Text>
        </label>

        <FormControlLabel
          control={
            <Switch
              checked={collectQuestionnaireAnswers}
              className="switcher switcher--default"
              data-testid="covid-schedule-times-collectAnswers"
              id='Collect answers'
              name='Collect answers'
              onChange={onUpdateCollectAnswers}
            />
          }
          label=""
        />
      </Box>
    </Box>
  );
}
